<template>
  <div id="home">
    <HeroSection />
    <QuestionSection />
    <CardsSection />
  </div>
</template>

<script>
import HeroSection from '@/components/hero-section.vue'
import QuestionSection from '@/components/question-section.vue'
import CardsSection from '@/components/cards-section.vue'

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    CardsSection,
    QuestionSection
  },
  data () {
    return {
    }
  }
}
</script>
