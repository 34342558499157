<template>
  <div :class="{'card': true, even}">
    <div class="card-image">
      <img :src="`/images/${item.image}.jpg`" :alt="item.title">
    </div>
    <div class="card-content">
      <div class="icon">
        <img :src="`/icons/${item.image}.png`" :alt="item.title">
      </div>
      <div class="content">
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    even: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang='scss' scoped>
  @import '@/variables.scss';

  .card {
    display: flex;
    width: 100%;
    align-items: center;
    .card-image {
      width: 60%;
      margin-right: -10%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-content {
      width: 65%;
      z-index: 10;
      border-radius: 3px;
      background-color: #fff;
      -webkit-box-shadow: 3px 3px 33px 2px rgba(0, 0, 0, 0.15);
      box-shadow: 3px 3px 33px 2px rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 60px 40px;
      .icon {
        width: 100px;
        img {
          width: 100%;
        }
      }
      .content {
        width: calc(100% - 100px);
        color: $--color-primary;
        h3 {
          font-size: 2.5rem;
          margin: 0 0 10px 0;
        }
        p {
          font-size: 1.1rem;
          margin: 0;
          font-weight: 100;
        }
      }
    }
    &.even {
      flex-direction: row-reverse;
      .card-content {
        flex-direction: row-reverse;
      }
      .card-image {
        margin-left: -10%;
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .card {
      .card-content {
        .icon {
          width: 80px;
        }
        .content {
          h3 {
            font-size: 1.8rem;
          }
          p {
            font-size: 0.9rem;
          };
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .card, .card.even {
      flex-direction: column-reverse;
      .card-image {
        width: 100%;
        margin: 0;
        img {
          margin-bottom: -5px;
        }
      }
      .card-content {
        box-shadow: none;
        width: 100%;
        border-radius: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 20px;
        gap: 15px;
        .content {
          text-align: center;
          width: 100%;
          p {
            line-height: 1.4rem;
          }
        }
      }
    }
  }
</style>
