<template>
  <section id="cards">
    <div class="container">
      <template v-for="(item, i) in items" :key="i">
        <Card :item="item" :even="((i + 1) % 2) === 0" />
      </template>
    </div>
  </section>
</template>

<script>
import Card from '@/components/card-item.vue'

export default {
  components: {
    Card
  },
  data () {
    return {
      items: [
        {
          title: 'Plus de personnalisation',
          description: 'Un conseiller dédié à votre disposition pour une relation digitale et personnalisée.',
          image: 'customization'
        },
        {
          title: 'Plus d\'expertise',
          description: 'Profiter d\'un accompagnement expert et proactif avec votre interlocuteur principal.',
          image: 'expertise'
        },
        {
          title: 'Plus de disponibilité',
          description: 'Contacts facilités (visio, mails, tel) et horaires étendus, de 8h à 21h du mardi au vendredi et de 8h à 18h le samedi .',
          image: 'availablity'
        }
      ]
    }
  }
}
</script>

<style lang='scss' scoped>
  section#cards {
    padding: 80px 0;
    .container {
      display: flex;
      flex-direction: column;
      gap: 80px;
    }
  }

  @media only screen and (max-width:1280px) {
    section#cards {
      .container {
        margin: 0 40px;
      }
    }
  }

  @media only screen and (max-width:650px) {
    section#cards {
      padding: 0;
      .container {
        margin: 0;
        gap: 0;
      }
    }
  }
</style>
