<template>
  <header>
    <div class="container">
      <a href="#">
        <img src="@/assets/images/bpri-logo.png" alt="BPRI - Logo">
      </a>
    </div>
  </header>
  <main>
    <router-view/>
  </main>
  <footer>
    <div class="container">
      <p>Les données à caractère personnel que vous nous communiquez sur ce formulaire sont traitées par la banque, responsable de traitement, à des fins de gestion interne et pour répondre à votre demande.</p>
      <p>Les informations vous expliquant de quelle manière vos données sont obtenues, pourquoi elles sont traitées, avec qui elles sont susceptibles d'être partagées, les mesures mises en œuvre pour assurer leur confidentialité et leur sécurité figurent dans notre Notice d'information sur les traitements des données à caractère personnel. Elle rappelle également les droits dont vous disposez et comment les exercer.</p>
      <p>Pour en savoir consultez notre <a href="#">notice d'information sur le traitement des données personnelles.</a></p>
    </div>
    <div class="container">
      <p>Société anonyme coopérative de Banque Populaire à capital variable, régie par les articles L. 512-2 et suivants du Code monétaire et financier et l'ensemble des textes relatifs aux Banques Populaires et aux établissements de crédit - 552 002 313 RCS Paris - Société immatriculée au Registre des Intermédiaires en Assurance sous le n° 07 022 545 - 80 boulevard Auguste Blanqui - 75204 Paris Cedex 13 - Téléphone : 01 73 07 48 37 - Télécopie : 01 73 07 78 05. Internet : <a href="https://www.banquepopulaire.fr/rivesparis" target="_blank" >www.banquepopulaire.fr/rivesparis</a>. Numéro d'identification intracommunautaire FR 59 552 002 313 Code APE 6419 Z</p>
    </div>
  </footer>
</template>

<style lang="scss">
  @import '@/variables.scss';

  body, html {
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }
  }

  #app, input, button, textarea {
    font-family: 'Ubuntu', sans-serif;
  }

  section {
    .container {
      margin: 0 auto;
      max-width: 1200px;
    }
  }

  header {
    background-color: #fff;
    height: 80px;
    .container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
      img {
        width: 165px;
      }
    }
  }
  footer {
    background-color: $--color-footer-background;
    .container {
      max-width: 795px;
      margin: 0 auto;
      padding: 40px;
      p {
        text-align: center;
        color: $--color-primary;
        font-size: .9rem;
        line-height: 1.5;
        margin: 0;
        font-weight: 300;
        a {
          color: $--color-primary;
          text-decoration: underline;}
      }
      &:last-child {
        padding-top: 0;
      }
    }
  }

  @media only screen and (max-width: 750px) {
    header {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.1);
      z-index: 100;
      .container {
        justify-content: center;
        align-items: center;
      }
    }
    footer {
      .container {
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
</style>
