<template>
  <section id="questions">
    <div class="container">
      <h3>Vous avez des questions sur le fonctionnement de votre nouvelle agence ?</h3>
      <a href="mailto:MyRive@rivesparis.banquepopulaire.fr">Nous contacter ici</a>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang='scss' scoped>
  @import '@/variables.scss';

  section#questions {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 40px;
      h3 {
        margin-top: 60px;
        text-align: center;
        color: $--color-primary;
        font-size: 2.3rem;
        max-width: 850px;
      }
      a {
        background-color: $--color-primary;
        color: #fff;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 5px;
      }
    }
  }

  @media only screen and (max-width: 910px) {
    section#questions {
      .container {
        h3 {
          font-size: 2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    section#questions {
      .container {
        margin: 0 20px 20px;
        h3 {
          font-size: 1.6rem;
        }
        a {
          font-size: .8rem;
        }
      }
    }
  }
</style>
