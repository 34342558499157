<template>
  <section id="hero-section">
    <div class="container">
      <div class="content">
        <h1>Imaginez une banque plus disponible, plus réactive, où que vous soyez</h1>
        <span>Rejoignez-nous pour écrire une nouvelle page !</span>
        <div class="form">
          <h2>Je participe</h2>
          <template v-if="status === 'success'">
            <p class="success-message">Votre inscription à la nouvelle banque à distance 100% digitale a bien été prise en compte. Nous reviendrons très rapidement vers vous avec la présentation détaillée de votre nouvelle agence.</p>
          </template>
          <template v-else>
            <div class="gender">
              <div class="gender-item">
                <label for="men">M</label>
                <input type="radio" name="men" v-model="form.gender" value="men">
              </div>
              <div class="gender-item">
                <label for="women">Mme</label>
                <input type="radio" name="women" v-model="form.gender" value="women">
              </div>
            </div>
            <div class="fields">
              <div class="field">
                <input type="text" placeholder="Nom" v-model="form.lastName">
              </div>
              <div class="field">
                <input type="text" placeholder="Prénom" v-model="form.firstName">
              </div>
              <div :class="{'field': true, 'error': status === 'error'}">
                <input type="email" placeholder="Adresse mail" v-model="form.email" :disabled="readonlyEmail">
              </div>
            </div>
            <p v-if="status === 'error'" class="error-message">Votre inscription n'a pas été prise en compte. Merci de renseigner votre adresse email client de votre agence Banque Populaire Rives de Paris.</p>
            <p v-if="status === 'already_exist'" class="error-message">Votre demande a déjà été enregistrée.</p>
            <div class="buttons">
              <button class="button button-primary" @click="submit" :disabled="loading">
                <div class="sk-chase" v-if="loading">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                </div>
                Valider
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { isEmail } from 'validator'
import axios from 'axios'
export default {
  data () {
    return {
      form: {
        gender: '',
        lastName: '',
        firstName: '',
        email: ''
      },
      readonlyEmail: false,
      loading: false,
      status: 'wait'
    }
  },
  methods: {
    submit () {
      if (!this.form.email || this.form.email.length === 0 || !isEmail(this.form.email)) {
        this.status = 'error'
        return
      }
      this.loading = true
      axios.post('/save', this.form).then((res) => {
        const response = res.data
        if (response.success) {
          this.status = 'success'
        } else if (response.message && response.message === 'already_exist') {
          this.status = 'already_exist'
        } else {
          this.status = 'error'
        }
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.status = 'error'
        this.loading = false
      })
    },
    pageLoaded () {
      axios.post('/loaded', this.form)
    }
  },
  mounted () {
    const urlDecoded = decodeURI(window.location.hash)
    const userInfos = urlDecoded.split(';').slice(1)
    if (userInfos.length === 5) {
      this.form = {
        ...this.form,
        gender: userInfos[0] === 'M' ? 'men' : 'women',
        lastName: userInfos[1],
        firstName: userInfos[2],
        email: userInfos[3],
        id: userInfos[4]
      }
    }
    this.$nextTick(() => {
      this.pageLoaded()
    })
  }
}
</script>

<style lang='scss' scoped>
  @import  '@/variables.scss';

  section#hero-section {
    background-image: url('@/assets/images/hero-section-bg.jpg');
    background-size: cover;
    background-position: center;
    aspect-ratio: 720 / 407;
    .container {
      padding: 30px;
      display: flex;
      align-items: center;
      height: 100%;
      .content {
        width: 70%;
        h1 {
          font-size: 2.3rem;
          color: #fff;
          margin-bottom: 0;
          max-width: 730px;
        }
        span {
          font-size: 1.1rem;
          color: #fff;
          margin: 20px 0;
          display: block;
          font-weight: 100;
        }
        .form {
          background-color: #fff;
          width: 75%;
          padding: 40px 50px;
          border-radius: 4px;
          h2 {
            margin: 0;
            font-size: 2.5rem;
            color: $--color-primary;
          }
          .success-message {
            font-size: 1.2rem;
            line-height: 1.8rem;
          }
          .error-message {
            font-size: 1rem;
            line-height: 1.4rem;
            color: #ff0000ab;
          }
          .gender {
            display: flex;
            margin: 20px 0;
            gap: 45px;
            .gender-item {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              gap: 8px;
              label {
                color: $--color-text-form;
              }
              input[type='radio'] {
              box-sizing: border-box;
              appearance: none;
              background: white;
              outline: 2px solid $--color-text-form;
              border: 2px solid white;
              width: 14px;
              height: 14px;
              border-radius: 1px;
              opacity: .7;
            }

            input[type='radio']:checked {
              background: $--color-text-form;
              border-radius: 1px;
            }
            }
          }
          .fields {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .field {
              input {
                border-radius: 0;
                width: 100%;
                border: none;
                border-bottom: solid 1px $--color-text-form-light;
                color: $--color-text-form;
                padding: 10px 0;
                font-size: .9rem;
                margin-bottom: 8px;
                &:placeholder {
                  color: $--color-text-form;
                }
                &:focus {
                  outline: none;
                  border-color: $--color-primary;
                }
              }
              &.error {
                input {
                  color: #ff0000ab;
                  border-color: #ff0000ab;
                }
              }
            }
          }
          .buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
            .button {
              color: #fff;
              border: none;
              padding: 10px 40px;
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              &.button-primary {
                background-color: $--color-primary;
              }
              &:disabled {
                opacity: .5;
                cursor: not-allowed;
                padding: 10px 40px 10px 20px;
              }
              .sk-chase {
                margin-right: 10px;
                width: 15px;
                height: 15px;
                position: relative;
                animation: sk-chase 2.5s infinite linear both;
              }

              .sk-chase-dot {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                animation: sk-chase-dot 2.0s infinite ease-in-out both;
              }

              .sk-chase-dot:before {
                content: '';
                display: block;
                width: 25%;
                height: 25%;
                background-color: #fff;
                border-radius: 100%;
                animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
              }

              .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
              .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
              .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
              .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
              .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
              .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
              .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
              .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
              .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
              .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
              .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
              .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

              @keyframes sk-chase {
                100% { transform: rotate(360deg); }
              }

              @keyframes sk-chase-dot {
                80%, 100% { transform: rotate(360deg); }
              }

              @keyframes sk-chase-dot-before {
                50% {
                  transform: scale(0.4);
                } 100%, 0% {
                  transform: scale(1.0);
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    section#hero-section {
      aspect-ratio: unset;
      padding: 60px 0;
    }
  }

  @media only screen and (max-width: 750px) {
    section#hero-section {
      .container {
        padding: 0 20px;
        .content {
          width: 100%;
          h1 {
            margin-top: 0;
          }
          .form {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    section#hero-section {
      .container {
        .content {
          h1 {
            font-size: 1.9rem;
          }
          span {
            font-size: .9rem;
          }
          .form {
            h2 {
              font-size: 1.9rem;
            }
            .gender {
              font-size: .9rem;
              .gender-item {
                input[type='radio'] {
                  width: 10px;
                  height: 10px;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1600px) {
    section#hero-section {
      height: 900px;
      aspect-ratio: unset;
    }
  }
</style>
